export const newEmployeeSchema = [
  {
    fieldName: "Name",
    fieldId: "name",
    fType: "textField",
  },
  {
    fieldName: "Mobile",
    fieldId: "mobile",
    fType: "textField",
  },
  {
    fieldName: "PAN CARD",
    fieldId: "pan_card",
    fType: "textField",
  },
  {
    fieldName: "DOJ",
    fieldId: "doj",
    fType: "date",
  },
  {
    fieldName: "Location",
    fieldId: "location",
    fType: "select",
    options: ["Bangalore1", "Bangalore2", "Hubballi"],
  },

  {
    fieldName: "DRA Number",
    fieldId: "dra_number",
    fType: "textField",
  },
  {
    fieldName: "PVC Expiry Date",
    fieldId: "pvc_expiry_date",
    fType: "date",
  },
  {
    fieldName: "Given CC ID",
    fieldId: "given_cc_id",
    fType: "radio",
    options: ["YES", "NO"],
  },
  {
    fieldName: "Current Address",
    fieldId: "current_address",
    fType: "textArea",
  },
  {
    fieldName: "Saving Account Details",
    fieldId: "saving_account_details",
    fType: "textArea",
  },
  {
    fieldName: "Relieving Date",
    fieldId: "relieving_date",
    fType: "date",
  },
];
export const banksDetails = {
  Kotak: {
    subFields: [
      {
        fieldName: "ID Card No",
        fieldId: "id_card_no",
        fType: "textField",
      },
    ],
  },
  ICICI: {
    subFields: [
      {
        fieldName: "ID Card No",
        fieldId: "id_card_no",
        fType: "textField",
      },
      {
        fieldName: "Mark ID",
        fieldId: "mark_id",
        fType: "textField",
      },
      {
        fieldName: "Vymo ID",
        fieldId: "vymo_id",
        fType: "textField",
      },
    ],
  },
  Axis: {
    subFields: [
      {
        fieldName: "HHD ID",
        fieldId: "hhd_id",
        fType: "textField",
      },
    ],
  },
  Bandhan: {
    subFields: [
      {
        fieldName: "ID Card No",
        fieldId: "id_card_no",
        fType: "textField",
      },
    ],
  },
};

export const fileDetails = [
  {
    fieldName: "File Name",
    fieldId: "file_name",
    fType: "textField",
  },
  {
    fieldName: "File",
    fieldId: "file",
    fType: "file",
  },
];
