import { ThemeProvider, createTheme } from "@mui/material/styles";
import Header from "./globalComponents/Header/Header";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import AddNewEmployee from "./components/Employee/AddNewEmployee";
// import Footer from "./globalComponents/Footer/Footer";

const theme = createTheme({
  // Override or create new styles, colors, palettes...
  palette: {
    // primary: {
    //   // main: "#19398a",
    // },
    icons: {
      main: "#FFF",
    },
    textColor: {
      main: "#FFF",
    },
  },
  typography: {
    h1: {
      fontSize: "64px",
      lineHeight: "64px",
      fontFamily: "Montserrat",
      "@media (max-width:600px)": {
        // for mobile screens
        fontSize: "36px",
        lineHeight: "36px",
      },
    },
    h2: {
      fontSize: "32px",
      lineHeight: "64px",
      fontFamily: "Montserrat",
      "@media (max-width:600px)": {
        // for mobile screens
        fontSize: "20px",
        lineHeight: "36px",
      },
    },
    h3: {
      fontSize: "24px",
      lineHeight: "64px",
      fontFamily: "Montserrat",
      "@media (max-width:600px)": {
        // for mobile screens
        fontSize: "20px",
        lineHeight: "36px",
      },
    },
    h6: {
      fontSize: "20px",
      // lineHeight: "64px",
      fontFamily: "Montserrat",
      "@media (max-width:600px)": {
        // for mobile screens
        fontSize: "16px",
        lineHeight: "36px",
      },
    },
    body1: {
      fontSize: "16px",
      // lineHeight: "64px",
      "@media (max-width:600px)": {
        // for mobile screens
        fontSize: "14px",
        lineHeight: "36px",
      },
    },
    subtitle1: {
      fontFamily: "Montserrat",
      fontSize: "22px",
      // lineHeight: "64px",
      fontWeight: "600",
      "@media (max-width:600px)": {
        // for mobile screens
        fontSize: "20px",
      },
    },
    subtitle2: {
      fontFamily: "Montserrat",
      fontSize: "14px",
      // lineHeight: "64px",
      fontWeight: "400",
      "@media (max-width:600px)": {
        // for mobile screens
        fontSize: "14px",
      },
    },
  },
});
function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <header>
          <Header />
        </header>
        <body>
          <Routes>
            <Route path="/register" element={<AddNewEmployee />} />
          </Routes>
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
        </body>

        <footer>{/* <Footer /> */}</footer>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
