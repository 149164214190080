import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const EmployeeList = ({ allEmployeesData }) => {
  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "mobile", headerName: "Mobile", flex: 1 },
    { field: "location", headerName: "Location", flex: 1 },
    {
      field: "current_address",
      headerName: "Address",
      flex: 2,
    },
    {
      field: "doj",
      headerName: "DOJ",
      flex: 1,
      valueFormatter: (doj) => {
        if (doj == null) {
          return "";
        }
        return `${new Date(doj).toLocaleDateString("en-GB")} `;
      },
    },
    { field: "dra_number", headerName: "DRA", flex: 1 },
    { field: "given_cc_id", headerName: "CC ID", flex: 1 },
    { field: "pan_card", headerName: "PAN", flex: 1 },
    {
      field: "pvc_expiry_date",
      headerName: "PVC Expiry",
      flex: 1,
      valueFormatter: (pvc_date) => {
        if (pvc_date == null) {
          return "";
        }
        return `${new Date(pvc_date).toLocaleDateString("en-GB")} `;
      },
    },
    {
      field: "saving_account_details",
      headerName: "A/c Details",
      flex: 2,
    },
    {
      field: "kotak",
      headerName: "Kotak ID",
      flex: 1,
      valueGetter: ({ id_card_no } = {}) => {
        // handling undefined
        return id_card_no;
      },
    },
    {
      field: "axis",
      headerName: "Axis ID",
      flex: 1,
      valueGetter: ({ hhd_id } = {}) => {
        return hhd_id;
      },
    },
    {
      field: "icici_id",
      headerName: "ICICI ID",
      flex: 1,
      valueGetter: (id_card_no) => {
        return id_card_no;
      },
    },
    {
      field: "icici_mark",
      headerName: "Mark ID",
      flex: 1,
      valueGetter: (mark_id) => {
        return mark_id;
      },
    },
    {
      field: "icici_vymo",
      headerName: "VYMO ID",
      flex: 1,
      valueGetter: (vymo_id) => {
        return vymo_id;
      },
    },
    {
      field: "bandhan",
      headerName: "Bandhan ID",
      flex: 1,
      valueGetter: ({ id_card_no } = {}) => {
        return id_card_no;
      },
    },
    {
      field: "files",
      headerName: "Files",
      flex: 3,
      renderCell: ({ row }) => {
        return (
          <ul
            style={{
              display: "flex",
              flexDirection: "row",
              margin: 0,
              padding: 0,
              listStyleType: "none",
            }}
          >
            {Object.keys(row.files).map((file) => {
              return (
                <li style={{ margin: "0 10px" }}>
                  <a href={row.files[file]} target="_blank" rel="noreferrer">
                    {file}
                  </a>
                </li>
              );
            })}
          </ul>
        );
      },
    },
  ];
  const rows =
    allEmployeesData &&
    allEmployeesData.map((row) => ({
      ...row,
      axis: row.banks.Axis,
      icici_id: row.banks.ICICI.id_card_no,
      icici_mark: row.banks.ICICI.mark_id,
      icici_vymo: row.banks.ICICI.vymo_id,
      kotak: row.banks.Kotak,
      bandhan: row.banks.Bandhan,
    }));

  //   const rows = [
  //     {
  //       mobile: "9886143737",
  //       relieving_date: "2025-03-24T13:57:42.000Z",
  //       pan_card: "DTCOP",
  //       doj: "2023-01-01T13:57:42.000Z",
  //       current_address: "HOME ADDRESS GOES HERE",
  //       saving_account_details: "BANK DETAILS GOES HERE",
  //       pvc_expiry_date: "2024-03-30T13:57:42.000Z",
  //       given_cc_id: "YES",
  //       files: {
  //         PAN: "https://firebasestorage.googleapis.com/v0/b/workforce-manager-991f9.appspot.com/o/emp_files%2Fdownload_c0e6bebd-99d1-4645-a672-eaa87b0cf2bd?alt=media&token=60ddfda6-4f38-436b-b776-8df012044f06",
  //         ID: "https://firebasestorage.googleapis.com/v0/b/workforce-manager-991f9.appspot.com/o/emp_files%2Fdownload_f47433b5-64cc-4898-9f3d-8e4a9069f1d2?alt=media&token=61433cda-5973-4ab1-bdd8-ef672733dcf6",
  //         AADHAR:
  //           "https://firebasestorage.googleapis.com/v0/b/workforce-manager-991f9.appspot.com/o/emp_files%2Fdownload_40f416b9-edb9-4e2c-b9df-49bd8d4f577e?alt=media&token=221024c5-7c76-4df0-aa80-9a6c6ce17738",
  //       },
  //       banks: {
  //         ICICI: {
  //           mark_id: "123",
  //           id_card_no: "123",
  //           vymo_id: "123",
  //         },
  //         Kotak: {
  //           id_card_no: "KOTAK123",
  //         },
  //       },
  //       created_date: {
  //         seconds: 1711288791,
  //         nanoseconds: 893000000,
  //       },
  //       location: "Bangalore1",
  //       dra_number: "DRA123",
  //       name: "KIRAN",
  //       id: "8epxUV1sV5fLTNtijkk7",
  //       icici_id: "123",
  //       icici_mark: "123",
  //       icici_vymo: "123",
  //       kotak: {
  //         id_card_no: "KOTAK123",
  //       },
  //     },
  //     {
  //       pvc_expiry_date: "2024-03-31T14:06:59.000Z",
  //       given_cc_id: "YES",
  //       created_date: {
  //         seconds: 1711462707,
  //         nanoseconds: 125000000,
  //       },
  //       relieving_date: "2024-03-26T14:06:59.022Z",
  //       files: {
  //         "AADHAR crad":
  //           "https://firebasestorage.googleapis.com/v0/b/workforce-manager-991f9.appspot.com/o/emp_files%2FWhatsApp%20Image%202024-03-17%20at%2017_39f9087f-9fa7-491a-966f-83d9e468dad1?alt=media&token=b993741d-3a42-4ea7-9cff-7659e43e9038",
  //         PAN: "https://firebasestorage.googleapis.com/v0/b/workforce-manager-991f9.appspot.com/o/emp_files%2Fpp_7f2aab24-b142-4e52-8125-4590b3743f2f?alt=media&token=edc35a43-b897-4623-a42c-f42a734be2e5",
  //       },
  //       doj: "2017-03-01T14:06:59.000Z",
  //       name: "Kiran",
  //       pan_card: "dtcpso8hf",
  //       location: "Hubballi",
  //       saving_account_details: "Account details",
  //       mobile: "9886143737",
  //       current_address: "Current address",
  //       dra_number: "DRA1234",
  //       banks: {
  //         Bandhan: {
  //           id_card_no: "ID ",
  //         },
  //         Axis: {
  //           hhd_id: "HHD id",
  //         },
  //         ICICI: {
  //           vymo_id: "vymo",
  //           mark_id: "mark",
  //           id_card_no: "ID CARD",
  //         },
  //         Kotak: {
  //           id_card_no: "ID KOTAK",
  //         },
  //       },
  //       id: "RiIGgnMjmbe14vtwhi2o",
  //       axis: {
  //         hhd_id: "HHD id",
  //       },
  //       icici_id: "ID CARD",
  //       icici_mark: "mark",
  //       icici_vymo: "vymo",
  //       kotak: {
  //         id_card_no: "ID KOTAK",
  //       },
  //       bandhan: {
  //         id_card_no: "ID ",
  //       },
  //     },
  //     {
  //       name: "SHREE",
  //       banks: {
  //         ICICI: {
  //           id_card_no: "122",
  //           vymo_id: "AA",
  //           mark_id: "21",
  //         },
  //         Kotak: {
  //           id_card_no: "343",
  //         },
  //         Axis: {
  //           hhd_id: "222",
  //         },
  //         Bandhan: {
  //           id_card_no: "999",
  //         },
  //       },
  //       dra_number: "6578886",
  //       saving_account_details: "8787878787777",
  //       given_cc_id: "YES",
  //       mobile: "9876543210",
  //       relieving_date: "2024-03-24T16:42:32.830Z",
  //       created_date: {
  //         seconds: 1711298892,
  //         nanoseconds: 530000000,
  //       },
  //       pvc_expiry_date: "2024-03-24T16:42:32.830Z",
  //       files: {
  //         DOC: "https://firebasestorage.googleapis.com/v0/b/workforce-manager-991f9.appspot.com/o/emp_files%2Fdownload_8f0944f6-b2c8-403f-b69f-b09865acc902?alt=media&token=144860ab-3b75-426a-9d9b-73bb3de9e1d3",
  //         PIC: "https://firebasestorage.googleapis.com/v0/b/workforce-manager-991f9.appspot.com/o/emp_files%2Fpp_8bbb0de4-620e-4657-8366-e22e42248ddc?alt=media&token=1100aad4-04fe-4f62-8a60-66bf6b312bad",
  //       },
  //       doj: "2024-03-24T16:42:32.830Z",
  //       pan_card: "ALNZ40",
  //       location: "Bangalore2",
  //       current_address: "HTEGGFGVHGS FDTFAWYD ",
  //       id: "f5n8C25CuZv45efVMqMy",
  //       axis: {
  //         hhd_id: "222",
  //       },
  //       icici_id: "122",
  //       icici_mark: "21",
  //       icici_vymo: "AA",
  //       kotak: {
  //         id_card_no: "343",
  //       },
  //       bandhan: {
  //         id_card_no: "999",
  //       },
  //     },
  //     {
  //       pan_card: "jhgjghjgh",
  //       saving_account_details: "sdf",
  //       files: {
  //         sdfsdf:
  //           "https://firebasestorage.googleapis.com/v0/b/workforce-manager-991f9.appspot.com/o/emp_files%2FWhatsApp%20Image%202024-03-21%20at%2019_d6b614c4-d578-4f90-8a7e-b84e5c0ce588?alt=media&token=a16ba65f-815a-48fc-a4c7-92d6d7e7a814",
  //       },
  //       doj: "2024-03-31T05:40:50.918Z",
  //       pvc_expiry_date: "2024-03-31T05:40:50.918Z",
  //       relieving_date: "2024-03-31T05:40:50.918Z",
  //       mobile: "jhgjhghj",
  //       banks: {
  //         ICICI: {
  //           mark_id: "sdfs",
  //           vymo_id: "sdf",
  //           id_card_no: "sdfsd",
  //         },
  //       },
  //       name: "ghghj",
  //       dra_number: "sdf",
  //       created_date: {
  //         seconds: 1711864044,
  //         nanoseconds: 170000000,
  //       },
  //       location: "Bangalore1",
  //       given_cc_id: "YES",
  //       current_address: "sdf",
  //       id: "hz6yGes9GJm9nMXCxw2i",
  //       icici_id: "sdfsd",
  //       icici_mark: "sdfs",
  //       icici_vymo: "sdf",
  //     },
  //   ];
  return (
    <>
      <DataGrid
        rows={rows}
        columns={columns}
        //   checkboxSelection={true}
        //   disableColumnResize={true}
        editMode="row"
        slots={{
          toolbar: GridToolbar,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
    </>
  );
};
export default EmployeeList;
