import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { banksDetails, newEmployeeSchema } from "./NewEmployeeSchema";
import { useState } from "react";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import useFirestore from "../../hooks/useFirestore";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CustomSnackBar from "../../utils/CustomSnackBar";

const renderFormFields = (
  field,
  handleChange,
  newEmpData,
  selectedBank = ""
) => {
  const { fieldName, fieldId, fType, options } = field;
  switch (fType) {
    case "textField": {
      return (
        <TextField
          label={fieldName}
          //required
          variant="outlined"
          id={fieldName}
          fullWidth
          onChange={(e) => handleChange(e, fieldId, selectedBank)}
        />
      );
    }
    case "textArea": {
      return (
        <TextField
          label={fieldName}
          //required
          multiline={true}
          minRows={3}
          variant="outlined"
          fullWidth
          id={fieldName}
          onChange={(e) => handleChange(e, fieldId, selectedBank)}
        />
      );
    }
    case "file": {
      return (
        <TextField
          label={fieldName}
          //required
          type="file"
          variant="outlined"
          fullWidth
          id={fieldName}
          onChange={(e) => handleChange(e, fieldId, selectedBank, fType)}
        />
      );
    }
    case "date": {
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            //required
            id={fieldName}
            label={fieldName}
            // defaultValue={dayjs("2022-04-17")}
            sx={{ width: "100%" }}
            value={dayjs(newEmpData[fieldId])}
            onChange={(newValue) =>
              handleChange(newValue, fieldId, selectedBank)
            }
            slotProps={{
              textField: {
                //required: true,
              },
            }}
          />
        </LocalizationProvider>
      );
    }
    case "select": {
      return (
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">{fieldId}</InputLabel>
          <Select
            //required
            id={fieldName}
            labelId="demo-simple-select-label"
            // value={age}
            label={fieldName}
            onChange={(e) => handleChange(e, fieldId, selectedBank)}
          >
            {options.map((option) => (
              <MenuItem value={option}>{option}</MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
    case "radio": {
      return (
        <FormControl
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <FormLabel id="demo-radio-buttons-group-label">{fieldName}</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
            id={fieldId}
            onChange={(e) => handleChange(e, fieldId, selectedBank)}
          >
            {options.map((option) => (
              <FormControlLabel
                value={option}
                control={<Radio />}
                label={option}
                id={fieldId}
              />
            ))}
          </RadioGroup>
        </FormControl>
      );
    }
    default:
      return null;
  }
};
const RenderBankDetails = ({ handleChange, newEmpData }) => {
  const [selectedBank, setSelectedBank] = useState();
  const banks = Object.keys(banksDetails);
  return (
    <Grid container>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{"Choose Bank"}</InputLabel>
        <Select
          //required
          id={"BANK"}
          labelId="demo-simple-select-label"
          // value={age}
          label={"Choose Bank"}
          onChange={(e) => {
            setSelectedBank(e.target.value);
          }}
        >
          {banks.map((bank) => (
            <MenuItem value={bank}>{bank}</MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedBank &&
        banksDetails[selectedBank]["subFields"].map((bankObj, bIndex) => (
          <Grid item md={12} sx={{ my: "10px" }}>
            {renderFormFields(bankObj, handleChange, newEmpData, selectedBank)}
          </Grid>
        ))}
    </Grid>
  );
};
const RenderNewFile = ({ newEmpData, setNewEmpData, uploadFileToDB }) => {
  const [fileType, setFileType] = useState("");
  const [file, setFile] = useState("");
  const [error, setError] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploadded, setIsUploaded] = useState(false);
  function handleChange(e) {
    if (e.target.type === "text") {
      setFileType(e.target.value);
      if (file !== "") {
        setError(false);
      }
    }
    if (e.target.type === "file") {
      setFile(e.target.files[0]);
      if (fileType !== "") {
        setError(false);
      }
    }
  }
  function handleUpload() {
    if (fileType === "" || file === "") {
      setError(true);
      return;
    }
    setIsUploading(true);
    const uploadPromise = uploadFileToDB(file);
    uploadPromise.then((fileUrl) => {
      console.log("value", fileUrl);
      setError(false);
      setIsUploading(false);
      setIsUploaded(true);
      const tempObj = { ...newEmpData };
      tempObj["files"][fileType] = fileUrl;
      console.log("temp in file obj ", tempObj);
      setNewEmpData({ ...tempObj });
    });
  }
  return (
    <Grid container>
      {error && (
        <span style={{ color: "#cc0000", display: error ? "block" : "none" }}>
          Enter details
        </span>
      )}

      <Grid item md={12}>
        <TextField
          label={"File Name"}
          //required
          variant="outlined"
          id={"file_name"}
          fullWidth
          onChange={(e) => handleChange(e)}
          sx={{ my: "10px" }}
        />
        <TextField
          label={"File"}
          type="file"
          //required
          variant="outlined"
          id={"file"}
          fullWidth
          onChange={(e) => handleChange(e)}
          sx={{ my: "10px" }}
        />
      </Grid>
      <Button
        variant="outlined"
        onClick={handleUpload}
        endIcon={isUploading && <CircularProgress size="17px" />}
      >
        {isUploadded ? (
          <>
            Uploaded
            <CheckCircleOutlineIcon
              sx={{ color: "green", marginLeft: "5px", fontSize: "20px" }}
            />
          </>
        ) : (
          "Upload"
        )}
      </Button>
    </Grid>
  );
};
const AddNewEmployee = () => {
  const { addEmployee, uploadFileToDB, success, setSuccess, loading } =
    useFirestore("employees");
  const [newEmpData, setNewEmpData] = useState({
    doj: new Date().toISOString(),
    pvc_expiry_date: new Date().toISOString(),
    relieving_date: new Date().toISOString(),
    banks: {},
    files: {},
  });
  const [addNewBank, setAddNewBank] = useState([]);
  const [addNewFile, setAddNewFile] = useState([]);
  // Snackbar variables

  const handleClose = (event, reason) => {
    console.log("here in close");
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
  };
  const dateTypes = ["doj", "pvc_expiry_date", "relieving_date"];
  const handleChange = (e, fieldId, selectedBank, fType) => {
    console.log("PARAMS ", fieldId, selectedBank, fType);
    if (dateTypes.includes(fieldId)) {
      console.log("in date");
      const tempObj = { ...newEmpData };
      tempObj[fieldId] = e.toISOString();
      setNewEmpData({ ...tempObj });
    } else if (selectedBank !== "" && selectedBank !== undefined) {
      console.log("in bank");
      const tempObj = { ...newEmpData };
      if (tempObj["banks"][selectedBank] === undefined) {
        tempObj["banks"][selectedBank] = {};
      } else {
        tempObj["banks"][selectedBank] = { ...tempObj["banks"][selectedBank] };
      }
      tempObj["banks"][selectedBank][fieldId] = e.target.value;
      setNewEmpData({ ...tempObj });
    } else if (fType) {
      console.log("in file if");
      const tempObj = { ...newEmpData };

      tempObj["files"].push(e.target.files[0]);
      console.log("files ", tempObj);
    } else {
      console.log("in normal");
      const tempObj = { ...newEmpData };
      tempObj[fieldId] = e.target.value;
      setNewEmpData({ ...tempObj });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("in Submit ", newEmpData);
    const postPayload = { ...newEmpData };
    postPayload["created_date"] = new Date();
    console.log("addNewFile ", addNewFile);
    if (addNewFile.length > 0 && Object.keys(newEmpData.files).length === 0) {
      alert("Upload the file");
    } else {
      addEmployee(postPayload);
    }
  };

  return (
    <>
      {/* {loading && (
        <div
          style={{
            position: "absolute",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            marginTop: "-4%",
          }}
        >
          <CircularProgress sx={{ color: "#FFF" }} />
        </div>
      )} */}
      {loading && <LinearProgress />}
      <Grid container sx={{ p: "0 20px" }}>
        <CustomSnackBar
          message="Employee added successfully!"
          open={success}
          handleClose={handleClose}
        />

        <Typography variant="h2">Fill the form</Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {newEmployeeSchema.map((field) => (
              <Grid item md={6}>
                {renderFormFields(field, handleChange, newEmpData)}
              </Grid>
            ))}
            <Grid item md={12}>
              <Button
                variant="contained"
                sx={{ my: 2 }}
                startIcon={<AddIcon />}
                onClick={() => setAddNewBank([...addNewBank, ""])}
              >
                Add Bank
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {addNewBank.map((bank, index) => (
              <Grid item md={3}>
                <RenderBankDetails
                  handleChange={handleChange}
                  newEmpData={newEmpData}
                />
              </Grid>
            ))}
          </Grid>
          <>
            <Grid item md={12}>
              <Button
                variant="contained"
                sx={{ my: 2 }}
                startIcon={<AddIcon />}
                onClick={() => setAddNewFile([...addNewFile, ""])}
              >
                Upload file
              </Button>
            </Grid>
            <Grid container spacing={2}>
              {addNewFile.map((bank, index) => (
                <Grid item md={3}>
                  <RenderNewFile
                    handleChange={handleChange}
                    newEmpData={newEmpData}
                    setNewEmpData={setNewEmpData}
                    uploadFileToDB={uploadFileToDB}
                  />
                </Grid>
              ))}
            </Grid>
          </>

          <Button variant="contained" type="submit" sx={{ mt: 2 }}>
            Submit
          </Button>
        </form>
      </Grid>
    </>
  );
};

export default AddNewEmployee;
