import { db } from "../firebase-config";
import { useState } from "react";
import { v4 } from "uuid";
import { collection, addDoc, getDocs } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL, getStorage } from "firebase/storage";

const useFirestore = (collectionName) => {
  const collectionRef = collection(db, collectionName);
  const storage = getStorage();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [allEmployees, setAllEmployees] = useState();

  const addEmployee = async (payload) => {
    setLoading(true);
    try {
      await addDoc(collectionRef, payload);
      setSuccess(true);
    } catch (error) {
      console.log("error ", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const uploadFileToDB = async (file) => {
    const filePath = `emp_files/${file.name.split(".")[0] + "_" + v4()}`;
    const storageRef = ref(storage, filePath);

    const response = await uploadBytes(storageRef, file);
    const url = await getDownloadURL(response.ref);
    setFileUrl(url);
    return url;
  };

  const getAllEmployees = async () => {
    setLoading(true);
    const data = await getDocs(collectionRef);
    setAllEmployees(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    setLoading(false);
  };

  return {
    addEmployee,
    uploadFileToDB,
    getAllEmployees,
    allEmployees,
    success,
    setSuccess,
    loading,
    error,
    fileUrl,
  };
};

export default useFirestore;
