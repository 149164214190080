import { Grid } from "@mui/material";

const { BarChart, PieChart } = require("@mui/x-charts");

const Charts = ({ allEmployeesData }) => {
  function getLocationCounts(data) {
    const locationCounts = {};

    // Count occurrences of each location
    data.forEach((item) => {
      const location = item.location;
      locationCounts[location] = (locationCounts[location] || 0) + 1;
    });

    return locationCounts;
  }

  const uniqueLocationsCntObj = getLocationCounts(allEmployeesData);

  const locationWiseData = [];
  Object.keys(uniqueLocationsCntObj).forEach((location, lIndex) => {
    const locObj = {};
    locObj["id"] = lIndex;
    locObj["value"] = uniqueLocationsCntObj[location];
    locObj["label"] = location;
    locationWiseData.push(locObj);
  });

  const allIds = [
    "DRA",
    "AXIS HDD",
    "Kotak",
    "Bandhan ID",
    "ICICI ID",
    "ICICI mark ID",
    "Vymo ID",
  ];
  const idsStats = {
    dra: 0,
    axis_hhd: 0,
    kotak_id: 0,
    bandhan_id: 0,
    icici_id: 0,
    icici_mark_id: 0,
    icici_vymo_id: 0,
  };
  allEmployeesData.forEach((emp) => {
    if (emp.dra_number) {
      idsStats["dra"]++;
    }
    if (emp.banks.Axis) {
      idsStats["axis_hhd"]++;
    }
    if (emp.banks.Kotak) {
      idsStats["kotak_id"]++;
    }
    if (emp.banks.Bandhan) {
      idsStats["bandhan_id"]++;
    }
    if (emp.banks.ICICI && emp.banks.ICICI.id_card_no) {
      idsStats["icici_id"]++;
    }
    if (emp.banks.ICICI && emp.banks.ICICI.mark_id) {
      idsStats["icici_mark_id"]++;
    }
    if (emp.banks.ICICI && emp.banks.ICICI.vymo_id) {
      idsStats["icici_vymo_id"]++;
    }
  });
  console.log("idsStats ", idsStats);
  console.log("calues ", Object.values(idsStats));
  const idsCnt = Object.values(idsStats);
  return (
    <Grid container sx={{ my: 5 }}>
      <Grid item md={6}>
        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: [...allIds],
              tickPlacement: "start",
              tickLabelPlacement: "middle",
            },
          ]}
          series={[{ data: [...idsCnt] }]}
          slots={{
            axisLabel: <h1>Hi</h1>,
          }}
          //   width={870}
          height={300}
        />
      </Grid>
      <Grid item md={6}>
        <PieChart
          series={[
            {
              data: [...locationWiseData],
              outerRadius: 150,
              //   cx: 500,
              arcLabel: (item) => `${item.label} (${item.value})`,
              //   highlightScope: { faded: "global", highlighted: "item" },
            },
          ]}
          sx={{
            "& .MuiChartsLegend-column": {
              display: "none",
            },
            "& text": {
              fill: "#FFF",
            },
          }}
          //   width={500}
          //   height={200}
        />
      </Grid>
    </Grid>
  );
};

export default Charts;
