import { Button } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Dashboard from "../components/Dashboard/Dashboard";

const Home = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const navigate = useNavigate();
  return (
    <>
      <Button variant="contained" onClick={() => navigate("/register")}>
        Add employee
      </Button>
      <Dashboard />
    </>
  );
};

export default Home;
