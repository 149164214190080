import EmployeeList from "./EmployeeList";
import useFirestore from "../../hooks/useFirestore";
import { useEffect } from "react";
import { LinearProgress } from "@mui/material";
import Charts from "./Charts";

const Dashboard = () => {
  const { getAllEmployees, allEmployees, loading } = useFirestore("employees");
  useEffect(() => {
    getAllEmployees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {loading || !allEmployees ? (
        <LinearProgress sx={{ mt: 10 }} />
      ) : (
        <>
          <Charts allEmployeesData={allEmployees} />
          <EmployeeList allEmployeesData={allEmployees} />
        </>
      )}
    </>
  );
};
export default Dashboard;
